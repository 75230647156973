// src/DrawerNavigation.js
import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { HiLogout } from 'react-icons/hi'; // Import icons from react-icons
import { FaClock } from 'react-icons/fa'; // Import a generic clock icon

const drawerWidth = 240;

const DrawerNavigation = ({ open, toggleDrawer }) => {
    const handleLogout = () => {
        sessionStorage.removeItem('token'); // Clear the token from session storage
        window.location.href = '/login'; // Redirect to login screen
    };

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    marginTop:"64px"
                },
            }}
            variant="persistent"
            anchor="right" // Changed to right
            open={open}
        >
            <Box >
                <Typography sx={{
                    textAlign:"right",
                    marginTop:"20px",
                    marginRight:"20px",
                    fontSize:"16px"
                }}>
                    مرحبا, {sessionStorage.getItem("name")||"ضيف"}
                </Typography>
                <div style={{
                    width:"100%",
                    backgroundColor:"#000",
                    height:"1px",
                    marginTop:"10px"
                }}>

                </div>
                <List>
                    <ListItem button component={Link} to="/clock-in-out">
                        <ListItemText primary="تسجيل دخول / خروج" sx={{
                            textAlign:"right",
                            marginRight:"10px",
                            color:"#000"
                        }}/>
                        <ListItemIcon><FaClock size={20} /></ListItemIcon>

                    </ListItem>
                    <ListItem button component={Link} to="/time-entries">
                        <ListItemText primary="جدول الدوام" sx={{
                            textAlign:"right",
                            marginRight:"10px",
                            color:"#000"

                        }}/>
                        <ListItemIcon><FaClock size={20} /></ListItemIcon> {/* Use a different icon if desired */}

                    </ListItem>
                    <ListItem button onClick={handleLogout} sx={{
                        cursor:"pointer",
                    }}>
                        <ListItemText primary="تسجيل الخروج" sx={{
                            textAlign:"right",
                            marginRight:"10px",
                            color:"red"

                        }}/>
                        <ListItemIcon><HiLogout size={20} color='red'/></ListItemIcon>

                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
};

export default DrawerNavigation;
