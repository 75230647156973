import React, { useState } from 'react';
import { clockIn, clockOut } from './apis'; // Import your API functions
import { Modal, Button, Typography } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import styles from './ClockInOutScreen.module.css'; // Import the CSS module

const ClockInOutScreen = () => {
    const [openModal, setOpenModal] = useState(false);
    const [responseModalOpen, setResponseModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalType, setModalType] = useState(''); // 'success' or 'error'
    const [richText, setRichText] = useState('');
    const [isClockingOut, setIsClockingOut] = useState(false);

    const handleClockIn = async () => {
        try {
            const response = await clockIn();
            if(response.status=="success"){
                setModalType('success');

            }
            else
            {
                setModalType('error');

            }
            setModalMessage(response.message);
        } catch (error) {
            setModalMessage(error.error || 'حدث خطأ أثناء تسجيل الدخول');
            setModalType('error');
        } finally {
            setOpenModal(true);
        }
    };

    const handleClockOut = async () => {
        if (!richText) {
            setModalMessage('الملخص مطلوب لتسجيل الخروج.');
            setModalType('error');
            setOpenModal(false); // Close the rich text editor modal
            setResponseModalOpen(true); // Open the response modal
                        return;
        }
        setRichText("")
        try {
            const response = await clockOut(richText);
            if(response.status=="success"){
                setModalType('success');

            }
            else
            {
                setModalType('error');

            }
            setModalMessage(response.message);
        } catch (error) {
            setModalMessage(error.error || 'حدث خطأ أثناء تسجيل الخروج');
            setModalType('error');
        } finally {
            setOpenModal(false); // Close the rich text editor modal
            setResponseModalOpen(true); // Open the response modal
        }
    };

    const handleCloseResponseModal = () => {
        setResponseModalOpen(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setIsClockingOut(false);
        setRichText('');
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>تسجيل الدخول والخروج</h1>
            <div className={styles.buttonContainer}>
            <Button 
                    className={styles.button} 
                    onClick={() => { setIsClockingOut(true); setOpenModal(true); }}
                    variant="contained" 
                    color="secondary"
                >
                    تسجيل الخروج
                </Button>
                <Button className={styles.button} onClick={handleClockIn} variant="contained" color="primary">
                    تسجيل الدخول
                </Button>
              
            </div>

            {/* Modal for clock out with rich text editor */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={styles.modal}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        {isClockingOut ? 'تسجيل الخروج' : (modalType === 'success' ? 'نجاح' : 'خطأ')}
                    </Typography>
                    {isClockingOut ? (
                        <>
                            <ReactQuill
                                value={richText}
                                onChange={setRichText}
                                placeholder="أدخل ملخصاً..."
                                theme="snow"
                                className={styles.quillEditor}
                            />
                            <Button 
                                onClick={handleClockOut} 
                                variant="contained" 
                                color="primary" 
                                sx={{ mt: 2,marginRight:"10px" }}
                            >
                                تأكيد تسجيل الخروج
                            </Button>
                        </>
                    ) : (
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            {modalMessage}
                        </Typography>
                    )}
                    <Button onClick={handleCloseModal} variant="outlined" color="primary" sx={{ mt: 2 }}>
                        إغلاق
                    </Button>
                </div>
            </Modal>

            {/* Response Modal */}
            <Modal
                open={responseModalOpen}
                onClose={handleCloseResponseModal}
                aria-labelledby="response-modal-title"
                aria-describedby="response-modal-description"
            >
                <div className={styles.responseModal}>
                    <Typography id="response-modal-title" variant="h6" component="h2">
                        {modalType === 'success' ? 'نجاح' : 'خطأ'}
                    </Typography>
                    <Typography id="response-modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                    <Button onClick={handleCloseResponseModal} variant="outlined" color="primary" sx={{ mt: 2 }}>
                        إغلاق
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ClockInOutScreen;
