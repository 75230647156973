// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { CssBaseline, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import DrawerNavigation from './DrawerNavigation';
import ClockInOutScreen from './ClockInOutScreen';
import TimeEntriesScreen from './TimeEntriesScreen';
import LoginScreen from './LoginScreen';
import { HiMenu } from 'react-icons/hi'; // Import the menu icon
import LoginScreenAdmin from './LoginScreenAdmin';
import TimeEntriesScreenAdmin from './TimeEntriesScreenAdmin';

const drawerWidth = 240;

const App = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const location = useLocation();

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const isLoginPage = location.pathname === '/login'||location.pathname === '/'||location.pathname === '/adminlogin'||location.pathname === '/time-entries-admin';

    return (
        <>
            <CssBaseline />
            {!isLoginPage && (
                <>
                    <AppBar position="fixed">
                        <Toolbar>
                            <Typography variant="h6" noWrap>
                                نظام الدوام في بومباستك
                            </Typography>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer}
                                style={{
                                    marginLeft: 'auto',
                                    zIndex: 1201, // Ensure it's above the drawer
                                }}
                            >
                                <HiMenu />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DrawerNavigation open={drawerOpen} toggleDrawer={toggleDrawer} />
                </>
            )}
            <main
                style={{
                    marginRight: isLoginPage ? 0 : (drawerOpen ? drawerWidth : 0),
                    marginLeft: isLoginPage ? 0 : (drawerOpen ? 0 : 0), // Ensure no margin on left when drawer is open
                    transition: 'margin 0.3s',
                }}
            >
               {!isLoginPage&& <Toolbar />}
                <Routes>
                <Route path="/" element={<LoginScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/adminlogin" element={<LoginScreenAdmin />} />
                <Route path="/clock-in-out" element={<ClockInOutScreen />} />
                <Route path="/time-entries" element={<TimeEntriesScreen />} />
                <Route path="/time-entries-admin" element={<TimeEntriesScreenAdmin />} />
                </Routes>
            </main>
        </>
    );
};

// Wrap the App component in Router
const AppWithRouter = () => (
    <Router>
        <App />
    </Router>
);

export default AppWithRouter;
