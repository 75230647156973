import React, { useState } from 'react';
import { adminlogin } from './apis'; // Import the login API function
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import styles from './LoginScreen.module.css'; // Import the CSS module

const LoginScreenAdmin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await adminlogin(email, password);
            if (response.status === 'success') {
                // Save token to sessionStorage
                sessionStorage.setItem('token', response.token);
                sessionStorage.setItem('name', response.name);
                // Navigate to clock-in-out page
                navigate('/time-entries-admin');
            } else {
                setError(response.msg || 'Login failed');
            }
        } catch (error) {
            setError('An error occurred while logging in');
            console.error('Login error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <h1 className={styles.title}>نظام الدوام في بومباستك</h1>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <input
                        type="email"
                        placeholder="البريد الإلكتروني"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className={styles.input}
                    />
                    <input
                        type="password"
                        placeholder="كلمة المرور"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className={styles.input}
                    />
                    <button type="submit" disabled={loading} className={styles.button}>
                        {loading ? 'تسجيل الدخول...' : 'تسجيل الدخول'}
                    </button>
                    {error && <p className={styles.error}>{error}</p>}
                </form>
            </div>
        </div>
    );
};

export default LoginScreenAdmin;
