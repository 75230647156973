// src/apis.js

const API_URL = 'https://timetrackingapi.bombastic.ps/api';
// const API_URL = 'http://localhost:3000/api';

export const login = async (email,password) => {
    try {
        const response = await fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email,password }),
        });
        return await response.json();
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

export const adminlogin = async (email,password) => {
    try {
        const response = await fetch(`${API_URL}/adminlogin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email,password }),
        });
        return await response.json();
    } catch (error) {
        console.error('Error logging in:', error);
        throw error;
    }
};

// Function to clock in
export const clockIn = async () => {
    try {
        const response = await fetch(`${API_URL}/clock-in`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`
            },
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error clocking in:', error);
        throw error;
    }
};

// Function to clock out
export const clockOut = async (summary) => {
    try {
        const response = await fetch(`${API_URL}/clock-out`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
            body: JSON.stringify({  summary }),
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error clocking out:', error);
        throw error;
    }
};

// Function to get time entries for a specific employee (without admin_notes)
export const getTimeEntries = async () => {
    try {
        const response = await fetch(`${API_URL}/time-entries`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching time entries:', error);
        throw error;
    }
};

export const getTimeEntriesAdmin = async (employee) => {
    try {
        const response = await fetch(`${API_URL}/time-entries-admin/${employee}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching time entries:', error);
        throw error;
    }
};

export const getEmployees = async () => {
    try {
        const response = await fetch(`${API_URL}/get-employees`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching time entries:', error);
        throw error;
    }
};

export const addTimeRecord = async (formData) => {
    
    try {
        const response = await fetch(`${API_URL}/add-time-record`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
            body: JSON.stringify({ 
                
                clockIn:formData.clockIn?new Date(formData.clockIn).toISOString():null,
                clockOut:formData.clockOut?new Date(formData.clockOut).toISOString():null,
                summary:formData.summary,
                employeeId:formData.employeeId }),
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error clocking out:', error);
        throw error;
    }
};

export const EditClockIn = async (clockIn,id) => {
    
    try {
        const response = await fetch(`${API_URL}/edit-clockIn`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
            body: JSON.stringify({ 
                
                clockIn:clockIn?new Date(clockIn).toISOString():null,
                id}),
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error clocking out:', error);
        throw error;
    }
};

export const EditClockOut = async (clockOut,id) => {
    
    try {
        const response = await fetch(`${API_URL}/edit-clockOut`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
            body: JSON.stringify({ 
                
                clockOut:clockOut?new Date(clockOut).toISOString():null,
                id}),
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error clocking out:', error);
        throw error;
    }
};


export const editAdminNote = async (note,id) => {
    try {
        const response = await fetch(`${API_URL}/EditAdminNote`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
            body: JSON.stringify({  note,
                id }),
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error clocking out:', error);
        throw error;
    }
};

export const deleteEntry = async (id) => {
    try {
        const response = await fetch(`${API_URL}/deleteEntry`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${sessionStorage.getItem("token")}`

            },
            body: JSON.stringify({ id }),
        });
        if(response.status==401){
            window.location.href="/"
        }
        return await response.json();
    } catch (error) {
        console.error('Error clocking out:', error);
        throw error;
    }
};
