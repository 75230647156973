import React, { useEffect, useState } from 'react';
import { getEmployees, getTimeEntriesAdmin, addTimeRecord, deleteEntry, editAdminNote, EditClockIn, EditClockOut } from './apis'; // Import your API functions
import styles from './TimeEntriesScreen.module.css'; // Import the CSS module
import { Modal, Button, AppBar, Toolbar, Typography, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Grid } from '@mui/material';
import Select from 'react-select'; // Import react-select
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

const TimeEntriesScreenAdmin = () => {
    const [entries, setEntries] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedMonthString, setSelectedMonthString] = useState("null");
    const [openModal, setOpenModal] = useState(false);
    const [openSummaryModal, setOpenSummaryModal] = useState(false);
    const [openEditNoteModal, setOpenEditNoteModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [editNoteData, setEditNoteData] = useState({ note: '', id: '' });
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [entryToDelete, setEntryToDelete] = useState(null);
    const [salaryInputs, setSalaryInputs] = useState({
        baseSalary: 0,
        bonuses: 0,
        deductions: 0,
        advances: 0,
        workHours: 0,
        leaveDays: 0,
        daysInMonth: 0,
        fridays: 0,
        extraHourLimit: 8,
        normalHourLimit: 6.8,
    });
    const [openSalaryModal, setOpenSalaryModal] = useState(false); // New state for salary modal
    const [editEntry, setEditEntry] = useState(null);
    const [expandedTables, setExpandedTables] = useState({});
    const [groupedEntries,setGroupedEntries]=useState([])
    const handleEditTime = (id, field, originalTime) => {
      setEditEntry({ id, field, originalTime });
    };
    
    const handleTimeChange = (event, originalDateTime, field) => {
      const newTime = event.target.value; // The new time in HH:mm format
      const [hours, minutes] = newTime.split(':');
    
      // Combine the original date with the new time
      const updatedDateTime = new Date(originalDateTime);
      updatedDateTime.setHours(hours, minutes);
    
      // Save or process the updated time (API call or update state)
      saveUpdatedTime(editEntry.id, field, updatedDateTime);
    
      // Reset edit mode
      setEditEntry(null);
    };
    
    const saveUpdatedTime = async (id, field, updatedDateTime) => {
      // Example API call to save the updated time
      // API.saveTimeEntry(id, { [field]: updatedDateTime })

      if(field=="clock_in"){

        const response=await EditClockIn(updatedDateTime,id)
        console.log(response)
      }
      else{
        const response=await EditClockOut(updatedDateTime,id)
        console.log(response)
      }
      fetchEntries()
      console.log(`Entry ID: ${id}, Updated ${field}:`, updatedDateTime.toISOString());
    };
    
    const handleOpenSummaryModal = (summary) => {
        setModalContent(summary);
        setOpenSummaryModal(true);
    };

    const handleCloseSummaryModal = () => {
        setOpenSummaryModal(false);
        setModalContent('');
    };

    const handleOpenEditNoteModal = (note, id) => {
        setEditNoteData({ note, id });
        setOpenEditNoteModal(true);
    };

    const handleCloseEditNoteModal = () => {
        setOpenEditNoteModal(false);
        setEditNoteData({ note: '', id: '' });
    };

    const [formData, setFormData] = useState({
        clockIn: '',
        clockOut: '',
        summary: '',
        employeeId: '',
    });

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const data = await getEmployees();
                console.log(data)
                let options = data.map(employee => ({
                    value: employee.id,
                    label: employee.name,
                    salary:employee.salary,
                    iban:employee.iban,
                    idNum:employee.idNum
                }));
                
                options.push({
                    value: 0,
                    label: "الكل",
                    salary:0,
                    iban:0,
                    idNum:0
                })
                setEmployees(options.reverse());
                setSelectedEmployee({
                    value: 0,
                    label: "الكل",
                    salary:0,
                    iban:0,
                    idNum:0
                })
            } catch (error) {
                console.error('Error fetching employees:', error);
            }
        };

        fetchEmployees();
    }, []);

    const fetchEntries = async () => {
        try {
            const data = await getTimeEntriesAdmin(selectedEmployee.value);
            console.log(data)
            setEntries(data);
            setGroupedEntries(groupByMonth(data))
        } catch (error) {
            console.error('Error fetching time entries:', error);
        }
    };

    useEffect(() => {

        if (selectedEmployee) {
        
            console.log(selectedEmployee)
            fetchEntries();
        }
    }, [selectedEmployee]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setFormData({
            clockIn: '',
            clockOut: '',
            summary: '',
            employeeId: '',
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        setFormData(prevState => ({
            ...prevState,
            [name]: value, // Keep the local time as is
            employeeId: selectedEmployee ? selectedEmployee.value : prevState.employeeId,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log(formData)
            await addTimeRecord(formData);
            handleCloseModal();
            fetchEntries();
        } catch (error) {
            console.error('Error adding time record:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteEntry(id);
            fetchEntries();
        } catch (error) {
            console.error('Error deleting entry:', error);
        }
    };

    const handleEditNote = async () => {
        try {
            await editAdminNote(editNoteData.note, editNoteData.id);
            handleCloseEditNoteModal();
            fetchEntries();
        } catch (error) {
            console.error('Error editing note:', error);
        }
    };

    const calculateTimeDifference = (clockIn, clockOut) => {
        if (!clockIn || !clockOut) return 0;
        const inTime = new Date(clockIn).getTime();
        const outTime = new Date(clockOut).getTime();
        const differenceInMs = outTime - inTime;
        const differenceInHours = (differenceInMs / (1000 * 60 * 60)).toFixed(2);
        return parseFloat(differenceInHours);
    };

    const groupByMonth = (entries) => {
        let firstMonth
        let chosen=false
        const  groupedEntries = entries.reduce((acc, entry) => {

            const monthYear = new Date(entry.clock_in).toLocaleString('default', { year: 'numeric', month: 'long' });
            if (!acc[monthYear]) {
                acc[monthYear] = [];
            }
            acc[monthYear].push(entry);
            if(!chosen)
           {
             firstMonth=monthYear
             chosen=true

            }
            return acc;
        }, {});
        setExpandedTables((prev) => ({
            ...prev,
            [firstMonth]: true, // Toggle between true/false
          }));
          return groupedEntries;
    };

    const calculateTotalHours = (entries) => {
        return entries.reduce((total, entry) => {
            const hours = calculateTimeDifference(entry.clock_in, entry.clock_out);
            return total + hours;
        }, 0).toFixed(2);
    };



    const customStyles = {
        container: (provided) => ({
            ...provided,
            direction: 'rtl',
            width: 200,
            marginTop: "20px",
            marginRight: "20px"
        }),
        menu: (provided) => ({
            ...provided,
            direction: 'rtl',
            width: 200,
        }),
        singleValue: (provided) => ({
            ...provided,
            direction: 'rtl',
            fontSize: '0.875rem',
        }),
        placeholder: (provided) => ({
            ...provided,
            direction: 'rtl',
            fontSize: '0.875rem',
        }),
        control: (provided) => ({
            ...provided,
            height: '36px',
            minHeight: '36px',
            fontSize: '0.875rem',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: '4px',
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
    };
    const handleOpenConfirmDialog = (id) => {
        setEntryToDelete(id);
        setOpenConfirmDialog(true);
    };
    
    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setEntryToDelete(null);
    };
    
    const handleConfirmDelete = async () => {
        try {
            await deleteEntry(entryToDelete);
            fetchEntries();
            handleCloseConfirmDialog();
        } catch (error) {
            console.error('Error deleting entry:', error);
        }
    };

    const handleChangeSalaryInput = (e) => {
        const { name, value } = e.target;
        setSalaryInputs((prev) => ({ ...prev, [name]: parseFloat(value) || 0 }));
    };

    
    const calculateSalary = () => {
        const {
            baseSalary,
            bonuses,
            deductions,
            advances,
            workHours,
            leaveDays,
            daysInMonth,
            fridays,
            extraHourLimit,
            normalHourLimit
        } = salaryInputs;
    
        const totalDays = daysInMonth - fridays;
        const calculatedExtraHourLimit = totalDays * extraHourLimit;
        const calculatedNormalHourLimit = totalDays * normalHourLimit;
    
        const totalWorkHours = workHours + leaveDays * 7;
    
        let extraHours = Math.max(0, totalWorkHours - calculatedExtraHourLimit);
        let extraSalary = extraHours * 1.5 * (baseSalary / calculatedExtraHourLimit); // Extra salary based on overtime
    
        let totalSalary = 0;
    
        if (totalWorkHours >= calculatedExtraHourLimit) {
            totalSalary = baseSalary + extraSalary + bonuses - deductions - advances;
        } else if (totalWorkHours >= calculatedNormalHourLimit) {
            totalSalary = baseSalary + bonuses - deductions - advances;
        } else {
            totalSalary = (totalWorkHours * (baseSalary / calculatedNormalHourLimit)) + bonuses - deductions - advances;
        }
    
        const deductionsHours = baseSalary - totalSalary - deductions + extraSalary - advances;
    
        return {
            totalSalary: totalSalary.toFixed(2),
            calculatedExtraHourLimit,
            calculatedNormalHourLimit,
            extraHours,
            extraSalary,
            deductionsHours,
            baseSalary,
            bonuses,
            deductions,
            advances,
            workHours,
            leaveDays,
        };
    };
    
    const handleOpenSalaryModal = () => {
        setOpenSalaryModal(true);
    };

    const handleCloseSalaryModal = () => {
        setOpenSalaryModal(false);
    };
    const salaryReport = calculateSalary();
    function getDaysInMonth(input) {
        // Create a date object from the input string
        const [monthName, year] = input.split(' ');
        const monthIndex = new Date(Date.parse(monthName +" 1, 2024")).getMonth(); // Get the month index (0-11)
        const date = new Date(year, monthIndex + 1, 0); // Date object for the last day of the month
    
        return date.getDate(); // Return the number of days in the month
    }
    function getNumberOfFridays(input) {
        // Get the number of days in the month
        const [monthName, year] = input.split(' ');
        const monthIndex = new Date(Date.parse(monthName + " 1, 2024")).getMonth(); // Get the month index (0-11)
        const date = new Date(year, monthIndex + 1, 0); // Date object for the last day of the month
        const daysInMonth = date.getDate(); // Number of days in the month
    
        // Calculate the number of Fridays
        let numberOfFridays = 0;
        for (let day = 1; day <= daysInMonth; day++) {
            const currentDate = new Date(year, monthIndex, day);
            if (currentDate.getDay() === 5) { // 5 corresponds to Friday
                numberOfFridays++;
            }
        }
    
        return numberOfFridays;
    }
    const handlePrint = () => {
        // Create a new window for printing
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Salary Report</title>');
        printWindow.document.write(`
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
                body {
                    font-family: 'Cairo', sans-serif;
                    margin: 20px;
                    color: #333;
                    direction: rtl;
                    text-align: right;
                }
                h1 {
                    text-align: center;
                    color: #662084;
                    margin-bottom: 10px;
                }
                h2 {
                    text-align: center;
                    color: #555;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
                .container {
                    max-width: 800px;
                    margin: auto;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin: 20px 0;
                }
                table, th, td {
                    border: 1px solid #ddd;
                }
                th, td {
                    padding: 8px;
                    text-align: right;
                }
                th {
                    background-color: #662084;
                    color: white;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                .info {
                    margin-bottom: 20px;
                }
                .info p {
                    font-size: 16px;
                    margin: 4px 0;
                }
                .info strong {
                    color: #662084;
                }
                .header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .header img {
                    width: 150px;
                }
                .header h3 {
                    margin: 10px 0;
                    color: #662084;
                }
            </style>
        `);
        printWindow.document.write('</head><body>');
        printWindow.document.write('<div class="container">');
        
        // Header with Company Logo and Name
        printWindow.document.write(`
            <div class="header">
                <a href="https://bombastic.ps">
                    <img src="https://api.bombastic.ps/uploads/Logo.png" alt="Company Logo">
                </a>
                <h3>شركة بومباستك</h3>
            </div>
        `);
        
        // Title and Subtitle
        printWindow.document.write(`<h2>${selectedMonthString}</h2>`);
        
        // Employee Details
        printWindow.document.write(`
            <div class="info">
                <p><strong>اسم الموظف:</strong> ${selectedEmployee.label}</p>
                <p><strong>الايبان:</strong> ${selectedEmployee.iban}</p>
                <p><strong>رقم الهوية:</strong> ${selectedEmployee.idNum}</p>
            </div>
        `);
        
        // Salary Details in a Table
        printWindow.document.write(`
            <table>
                <thead>
                    <tr>
                        <th>الوصف</th>
                        <th>المبلغ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>الراتب الأساسي:</td>
                        <td>${salaryInputs.baseSalary}₪</td>
                    </tr>
                    <tr>
                        <td>ساعات الدوام:</td>
                        <td>${salaryInputs.workHours}/${salaryReport.calculatedNormalHourLimit.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>خصومات الساعات:</td>
                        <td>${salaryReport.deductionsHours.toFixed(2)}₪</td>
                    </tr>
                    <tr>
                        <td>أيام الإجازات:</td>
                        <td>${salaryInputs.leaveDays}</td>
                    </tr>
                    <tr>
                        <td>الساعات الإضافية:</td>
                        <td>${salaryReport.extraHours.toFixed(2)}</td>
                    </tr>
                    <tr>
                        <td>الراتب الإضافي:</td>
                        <td>${salaryReport.extraSalary.toFixed(2)}₪</td>
                    </tr>
                    <tr>
                        <td>المكافآت:</td>
                        <td>${salaryInputs.bonuses}₪</td>
                    </tr>
                    <tr>
                        <td>الخصومات:</td>
                        <td>${salaryInputs.deductions}₪</td>
                    </tr>
                    <tr>
                        <td>سلف:</td>
                        <td>${salaryInputs.advances}₪</td>
                    </tr>
                    <tr>
                        <td><strong>الراتب النهائي:</strong></td>
                        <td><strong>${salaryReport.totalSalary}₪</strong></td>
                    </tr>
                </tbody>
            </table>
        `);
    
        printWindow.document.write('</div>');
        printWindow.document.write('</body></html>');
        printWindow.document.close(); // Necessary for IE >= 10
        printWindow.focus(); // Necessary for IE >= 10
    
        printWindow.print();
    };
    
    
  const toggleTable = (month) => {
    setExpandedTables((prev) => ({
      ...prev,
      [month]: !prev[month], // Toggle between true/false
    }));
  };
    // Function to handle printing
    const handlePrintTable = (monthYear) => {
        const table = document.getElementById(`table-${monthYear}`);
        const printWindow = window.open('', '', 'height=600,width=800');
      
        printWindow.document.write('<html><head><title>Print Table</title>');
        printWindow.document.write(`
          <style>
            @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');
            body {
              font-family: 'Cairo', sans-serif;
              margin: 20px;
              color: #333;
              direction: rtl;
              text-align: right;
            }
            .header {
              text-align: center;
              margin-bottom: 20px;
            }
            .header img {
              width: 150px;
            }
            .header h3 {
              margin: 10px 0;
              color: #662084;
            }
            .print-header {
              text-align: center;
              font-size: 18px;
              margin-bottom: 10px;
              color: #662084;
            }
            .total-hours {
              font-size: 18px;
              margin-bottom: 10px;
              color: #662084;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin: 20px 0;
              direction: rtl; /* RTL direction for table */
            }
            table, th, td {
              border: 1px solid #ddd;
            }
            th, td {
              padding: 8px;
              text-align: right;
            }
            th {
              background-color: #662084;
              color: white;
            }
            tr:nth-child(even) {
              background-color: #f2f2f2;
            }
           
                th:nth-child(7), th:nth-child(9), th:nth-child(8),
              td:nth-child(7), td:nth-child(9), td:nth-child(8) {
                display: none; /* Hide "ملخص" and "حذف" columns */
              }
          </style>
        `);
        printWindow.document.write('</head><body>');
      
        // Header with Company Logo and Name
        printWindow.document.write(`
          <div class="header">
            <a href="https://bombastic.ps">
              <img src="https://api.bombastic.ps/uploads/Logo.png" alt="Company Logo">
            </a>
            <h3>شركة بومباستك</h3>
          </div>
        `);
      
        // Print Header with Employee and Month
        printWindow.document.write(`
          <div class="print-header">
            جدول دوام الموظف <strong>${selectedEmployee.label}</strong> لشهر <strong>${monthYear}</strong>
          </div>
             <div class="total-hours">
           اجمالي ساعات العمل : <strong>${calculateTotalHours(groupedEntries[monthYear])}</strong> ساعة
          </div>

        `);
      
        // Include Table
        printWindow.document.write(table.outerHTML);
      
        printWindow.document.write('</body></html>');
        printWindow.document.close(); // Necessary for IE >= 10
        printWindow.focus(); // Necessary for IE >= 10
      
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
          }, 2000);
        
        };
      
      
    return (
        <div className={styles.container}>
            <AppBar position="static" color='secondary'>
                <Toolbar>
                    <Typography variant="h6">
                        نظام الدوام في بومباستك | مرحبا, {sessionStorage.getItem('name')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                <Select
                    options={employees}
                    value={selectedEmployee}
                    onChange={setSelectedEmployee}
                    placeholder="اختر موظفاً"
                    className={styles.select}
                    classNamePrefix="react-select"
                    isClearable
                    styles={customStyles}
                />
                     {selectedEmployee && selectedEmployee.value!=0 &&(
                    <>
                        <Button variant="outlined" color="secondary" onClick={handleOpenModal} style={{ marginLeft: "10px", marginTop: "10px" }}>
                            إضافة سجل وقت جديد
                        </Button>
                       
                    </>
                )}
            </div>

            {/* Salary Modal */}
            <Modal open={openSalaryModal} onClose={handleCloseSalaryModal} aria-labelledby="salary-modal-title">
                <div className={styles.modalContainer}>
                    <h2 id="salary-modal-title">حساب الراتب</h2>

                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="baseSalary"
                                label="الراتب الأساسي"
                                value={salaryInputs.baseSalary}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="bonuses"
                                label="المكافآت"
                                value={salaryInputs.bonuses}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="deductions"
                                label="الخصومات"
                                value={salaryInputs.deductions}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="advances"
                                label="السلف"
                                value={salaryInputs.advances}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="workHours"
                                label="ساعات العمل"
                                value={salaryInputs.workHours}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="leaveDays"
                                label="أيام الإجازات"
                                value={salaryInputs.leaveDays}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="daysInMonth"
                                label="أيام الشهر"
                                value={salaryInputs.daysInMonth}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="fridays"
                                label="أيام الجمعة"
                                value={salaryInputs.fridays}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="extraHourLimit"
                                label="ساعات العمل الإضافية"
                                value={salaryInputs.extraHourLimit}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <TextField
                                name="normalHourLimit"
                                label="ساعات العمل الطبيعية"
                                value={salaryInputs.normalHourLimit}
                                onChange={handleChangeSalaryInput}
                                fullWidth
                            />
                        </Grid>
                    </Grid>

                    <div style={{ marginTop: "20px" }}>
                        <strong>الراتب النهائي:</strong> {salaryReport.totalSalary} شيكل
                    </div>

                    <h2 id="salary-modal-title">تقرير الراتب</h2>
                    <Grid container spacing={2} style={{ marginTop: "20px" }}>
                        <Grid item xs={6} sm={4}>
                            <Typography>الراتب الأساسي:</Typography>
                            <Typography>{salaryInputs.baseSalary}₪</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>ساعات الدوام:</Typography>
                            <Typography>{salaryInputs.workHours}/{salaryReport.calculatedNormalHourLimit.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>خصومات الساعات:</Typography>
                            <Typography>{salaryReport.deductionsHours.toFixed(2)}₪</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>أيام الإجازات:</Typography>
                            <Typography>{salaryInputs.leaveDays}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>الساعات الإضافية:</Typography>
                            <Typography>{salaryReport.extraHours.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>الراتب الإضافي:</Typography>
                            <Typography>{salaryReport.extraSalary.toFixed(2)}₪</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>المكافآت:</Typography>
                            <Typography>{salaryInputs.bonuses}₪</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>الخصومات:</Typography>
                            <Typography>{salaryInputs.deductions}₪</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>سلف:</Typography>
                            <Typography>{salaryInputs.advances}₪</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                            <Typography>الراتب النهائي:</Typography>
                            <Typography>{salaryReport.totalSalary}₪</Typography>
                        </Grid>
                    </Grid>

                    <Button onClick={handleCloseSalaryModal} variant="outlined" color="secondary" style={{ marginTop: "10px" }}>
                        إغلاق
                    </Button>
                    <Button onClick={handlePrint} variant="outlined" color="secondary" style={{ marginTop: "10px",marginRight:"20px" }}>
                        طباعة
                    </Button>
                </div>
            </Modal>
        
            {selectedEmployee && (
               <div>
               {Object.keys(groupedEntries).map((monthYear) => (
                 <div key={monthYear} style={{
                    padding:"10px"
                 }}>
                   <div className={styles.tableHeader}>
                   <Button onClick={() => toggleTable(monthYear)}>
                       {expandedTables[monthYear] ? <MdExpandLess size={30}/> : <MdExpandMore size={30}/>}
                     </Button>
                     <span>{monthYear}</span>
                    {
                        selectedEmployee.value!=0 &&(

                        <Button variant="outlined" color="secondary" sx={{
                                height:"30px",  
                                marginRight:"10px",
                                marginBottom:"10px"
                               
                            }} onClick={()=>{
                                setSalaryInputs({
                                    baseSalary: 0,
                                    bonuses: 0,
                                    deductions: 0,
                                    advances: 0,
                                    workHours: 0,
                                    leaveDays: 0,
                                    daysInMonth: 0,
                                    fridays: 0,
                                    extraHourLimit: 8,
                                    normalHourLimit: 6.8,
                                })
                                        setSalaryInputs((prev) => ({ ...prev, ["workHours"]: calculateTotalHours(groupedEntries[monthYear])*1 || 0 }));
                                        setSalaryInputs((prev) => ({ ...prev, ["daysInMonth"]: getDaysInMonth(monthYear) || 0 }));
                                        setSalaryInputs((prev) => ({ ...prev, ["fridays"]: getNumberOfFridays(monthYear) || 0 }));
                                        setSalaryInputs((prev) => ({ ...prev, ["baseSalary"]: selectedEmployee.salary || 0 }));
                                        setSelectedMonthString(monthYear)
                                        handleOpenSalaryModal()
                            }} style={{ marginLeft: "10px", marginTop: "10px" }}>
                            حساب الراتب
                        </Button>       
                        )
                    }
                   <Button variant="outlined" color="secondary" sx={{
                                height:"30px",  
                                marginRight:"10px",
                                marginBottom:"10px"
                               
                            }} onClick={() => handlePrintTable(monthYear)} style={{ marginLeft: "10px", marginTop: "10px" }}>
              طباعة
            </Button>
                   </div>
               
                   {expandedTables[monthYear] && (
                     <table id={`table-${monthYear}`} className={styles.table}>
                       <thead>
                         <tr>
                           <th>#</th>
                           <th>اسم الموظف</th>
                           <th>تاريخ الدخول</th>
                           <th>تسجيل الدخول</th>
                           <th>تسجيل الخروج</th>
                           <th>عدد ساعات العمل</th>
                           <th  className="summary-col">ملخص العمل</th>
                           <th>ملاحظات الإدارة</th>
                           <th  className="delete-col">حذف</th>
                         </tr>
                       </thead>
                       <tbody>
                         {groupedEntries[monthYear].map((entry) => (
                           <tr key={entry.id} className={entry.clock_out ? styles.rowNormal : styles.rowNoClockOut}>
                             <td>{entry.id}</td>
                             <td>{entry.name}</td>
                             <td>{new Date(entry.clock_in).toLocaleDateString()}</td>
         
                             {/* Clock In Time */}
                             <td onDoubleClick={() => handleEditTime(entry.id, 'clock_in', entry.clock_in)}>
                               {editEntry?.id === entry.id && editEntry?.field === 'clock_in' ? (
                                 <input
                                   type="time"
                                   defaultValue={new Date(entry.clock_in).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                   onBlur={(e) => handleTimeChange(e, entry.clock_in, 'clock_in')}
                                 />
                               ) : (
                                 new Date(entry.clock_in).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                               )}
                             </td>
         
                             {/* Clock Out Time */}
                             <td onDoubleClick={() => handleEditTime(entry.id, 'clock_out', entry.clock_out)}>
                               {entry.clock_out ? (
                                 editEntry?.id === entry.id && editEntry?.field === 'clock_out' ? (
                                   <input
                                     type="time"
                                     defaultValue={new Date(entry.clock_out).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                     onBlur={(e) => handleTimeChange(e, entry.clock_out, 'clock_out')}
                                   />
                                 ) : (
                                   new Date(entry.clock_out).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
                                 )
                               ) : (
                                 'غير متوفر'
                               )}
                             </td>
         
                             <td>
                               {entry.clock_out ? calculateTimeDifference(entry.clock_in, entry.clock_out) : 'غير متوفر'}
                             </td>
                             <td  className="summary-col">
                               <Button
                                 variant="outlined"
                                 color="secondary"
                                 onClick={() => handleOpenSummaryModal(entry.summary || 'لا يوجد ملخص')}
                               >
                                 عرض الملخص
                               </Button>
                             </td>
                             <td
                               onDoubleClick={() => handleOpenEditNoteModal(entry.admin_notes || '', entry.id)}
                               style={{ cursor: 'pointer' }}
                             >
                               {entry.admin_notes || 'لا يوجد ملاحظات'}
                             </td>
                             <td  className="delete-col">
                               <Button variant="outlined" color="error" onClick={() => handleOpenConfirmDialog(entry.id)}>
                                 حذف
                               </Button>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                     </table>
                     
                   )}
                     <div className={styles.totalHours}>
                        <strong>إجمالي ساعات العمل:</strong> {calculateTotalHours(groupedEntries[monthYear])} ساعة
                    </div>
                 </div>
               ))}
             </div>
            )}

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={styles.modalContainer}>
                    <h2 id="modal-title">إضافة سجل وقت جديد</h2>
                    <form id="modal-description" onSubmit={handleSubmit}>
                        <p style={{
                            marginBottom:"0px"
                        }}>
                            تسجيل الدخول
                            </p>
                        <TextField
                            type="datetime-local"
                            name="clockIn"
                            value={formData.clockIn}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                          
                        />
                          <p style={{
                            marginBottom:"0px"
                        }}>
                            تسجيل الخروج
                            </p>
                        <TextField
                            type="datetime-local"
                            name="clockOut"
                            value={formData.clockOut}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                         <p style={{
                            marginBottom:"0px"
                        }}>
                           ملخص العمل
                            </p>
                        <TextField
                            name="summary"
                            value={formData.summary}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                        />
                        <Button type="submit" variant="outlined" color="secondary" style={{
                            marginLeft:"10px"
                        }}>
                            إضافة
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
                            إغلاق
                        </Button>
                    </form>
                </div>
            </Modal>

            <Modal
                open={openEditNoteModal}
                onClose={handleCloseEditNoteModal}
                aria-labelledby="edit-note-modal-title"
                aria-describedby="edit-note-modal-description"
            >
                <div className={styles.modalContainer}>
                    <h2 id="edit-note-modal-title">تعديل ملاحظة الإدارة</h2>
                    <TextField
                        label="ملاحظة الإدارة"
                        value={editNoteData.note}
                        onChange={(e) => setEditNoteData(prev => ({ ...prev, note: e.target.value }))}
                        fullWidth
                        margin="normal"
                    />
                    <Button variant="outlined" color="secondary" onClick={handleEditNote} style={{
                        marginLeft:"10px"
                    }}>
                        حفظ
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseEditNoteModal}>
                        إغلاق
                    </Button>
                </div>
            </Modal>

            <Modal
                open={openSummaryModal}
                onClose={handleCloseSummaryModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
               
            >
                <div className={styles.modalContainer}>
                    <h2 id="modal-title">ملخص</h2>
                    <div id="modal-description" className={styles.modalDescription} dangerouslySetInnerHTML={{ __html: modalContent }} />
                    <Button variant="outlined" color="secondary" onClick={handleCloseSummaryModal}>إغلاق</Button>
                </div>
            </Modal>
            <Dialog
    open={openConfirmDialog}
    onClose={handleCloseConfirmDialog}
    aria-labelledby="confirm-dialog-title"
    aria-describedby="confirm-dialog-description"
>
    <DialogTitle id="confirm-dialog-title">تأكيد الحذف</DialogTitle>
    <DialogContent>
        <DialogContentText id="confirm-dialog-description">
            هل أنت متأكد أنك تريد حذف هذا السجل؟
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseConfirmDialog} color="secondary">
            إلغاء
        </Button>
        <Button onClick={handleConfirmDelete} color="secondary">
            حذف
        </Button>
    </DialogActions>
</Dialog>

        </div>
    );
};

export default TimeEntriesScreenAdmin;
