import React, { useEffect, useState } from 'react';
import { getTimeEntries } from './apis'; // Import your API functions
import styles from './TimeEntriesScreen.module.css'; // Import the CSS module
import { Modal, Button } from '@mui/material'; // Import Material-UI components for modal

const TimeEntriesScreen = () => {
    const [entries, setEntries] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    useEffect(() => {
        const fetchEntries = async () => {
            try {
                const data = await getTimeEntries();
                setEntries(data);
            } catch (error) {
                console.error('Error fetching time entries:', error);
            }
        };

        fetchEntries();
    }, []);

    // Function to calculate time difference in hours considering date and time
    const calculateTimeDifference = (clockIn, clockOut) => {
        if (!clockIn || !clockOut) return 0;
        const inTime = new Date(clockIn).getTime();
        const outTime = new Date(clockOut).getTime();
        const differenceInMs = outTime - inTime;
        const differenceInHours = (differenceInMs / (1000 * 60 * 60)).toFixed(2);
        return parseFloat(differenceInHours);
    };

    // Group entries by month
    const groupByMonth = (entries) => {
        return entries.reduce((acc, entry) => {
            const monthYear = new Date(entry.clock_in).toLocaleString('default', { year: 'numeric', month: 'long' });
            if (!acc[monthYear]) {
                acc[monthYear] = [];
            }
            acc[monthYear].push(entry);
            return acc;
        }, {});
    };

    // Calculate total hours for a given month
    const calculateTotalHours = (entries) => {
        return entries.reduce((total, entry) => {
            const hours = calculateTimeDifference(entry.clock_in, entry.clock_out);
            return total + hours;
        }, 0).toFixed(2);
    };

    // Open modal with summary content
    const handleOpenModal = (summary) => {
        setModalContent(summary);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setModalContent('');
    };

    const groupedEntries = groupByMonth(entries);

    return (
        <div className={styles.container}>
            {Object.keys(groupedEntries).map((monthYear) => (
                <div key={monthYear} className={styles.tableContainer}>
                    <h2 className={styles.monthHeader}>{monthYear}</h2>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>تاريخ الدخول</th>
                                <th>تسجيل الدخول</th>
                                <th>تسجيل الخروج</th>
                                <th>عدد ساعات العمل</th>
                                <th>ملخص العمل</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groupedEntries[monthYear].map((entry) => (
                                <tr 
                                    key={entry.id}
                                    className={entry.clock_out ? styles.rowNormal : styles.rowNoClockOut} // Apply conditional styling
                                >
                                    <td>{entry.id}</td>
                                    <td>{new Date(entry.clock_in).toLocaleDateString()}</td>
                                    <td>{new Date(entry.clock_in).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
                                    <td>{entry.clock_out ? new Date(entry.clock_out).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'غير متوفر'}</td>
                                    <td>{entry.clock_out ? calculateTimeDifference(entry.clock_in, entry.clock_out) : 'غير متوفر'}</td>
                                    <td>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            onClick={() => handleOpenModal(entry.summary || 'لا يوجد ملخص')}>
                                            عرض الملخص
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={styles.totalHours}>
                        <strong>إجمالي ساعات العمل:</strong> {calculateTotalHours(groupedEntries[monthYear])} ساعة
                    </div>
                </div>
            ))}

            {/* Modal to show summary */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <div className={styles.modalContainer}>
                    <h2 id="modal-title">ملخص</h2>
                    <div id="modal-description" dangerouslySetInnerHTML={{ __html: modalContent }} />
                    <Button variant="contained" color="primary" onClick={handleCloseModal}>إغلاق</Button>
                </div>
            </Modal>
        </div>
    );
};

export default TimeEntriesScreen;
